import './bootstrap';

let uuid;
let interval;

if (document.getElementById('js-generate_imagine')) {
    const image = document.getElementById('js-generate-image');
    const like1 = document.getElementById('js-like-1');
    const like2 = document.getElementById('js-like-2');
    const like3 = document.getElementById('js-like-3');
    const like4 = document.getElementById('js-like-4');

    image.src = image.dataset.src
    image.onload = function () {

        const xy = 7;
        const like_xy = 55;

        like1.style.left = (image.offsetLeft + xy) + 'px';
        like1.style.top = (image.offsetTop + xy) + 'px';
        like1.classList.remove('d-none')

        like2.style.left = (image.offsetLeft + image.offsetWidth - like_xy - xy) + 'px';
        like2.style.top = (image.offsetTop + xy) + 'px';
        like2.classList.remove('d-none')

        like3.style.left = (image.offsetLeft + xy) + 'px';
        like3.style.top = (image.offsetTop + image.offsetHeight - like_xy - xy) + 'px';
        like3.classList.remove('d-none')

        like4.style.left = (image.offsetLeft + image.offsetWidth - like_xy - xy) + 'px';
        like4.style.top = (image.offsetTop + image.offsetHeight - like_xy - xy) + 'px';
        like4.classList.remove('d-none')
    }

    function like(index) {
        document.getElementById('position').value = index
        new bootstrap
            .Modal(document.getElementById('modalImagine')).show();
    }

    like1.addEventListener('click', function (event) {
        like(1)
    });

    like2.addEventListener('click', function (event) {
        like(2)
    });

    like3.addEventListener('click', function (event) {
        like(3)
    });

    like4.addEventListener('click', function (event) {
        like(4)
    });
}

let isGenerate = false

if (document.getElementById('js-generate'))
    document.getElementById('js-generate').addEventListener('submit', function (event) {
        event.preventDefault();
        if (isGenerate) {
            return;
        }
        isGenerate = true;
        document.getElementById('js-loading-generate').classList.remove('d-none')
        document.getElementById('js-button-generate').classList.add('d-none')
        document.getElementById('js-error-generate').classList.add('d-none')

        axios.post('/api/generate', {
            text: document.getElementById('text').value,
        })
            .then(function (data) {
                uuid = data.data.uuid;
                document.getElementById('js-attempts').innerHTML = data.data.attempts;

                interval = setInterval(function () {
                    axios.post('/api/imagine/' + uuid)
                        .then(function (data) {
                            if (data.data.url.length > 0) {
                                clearInterval(interval);
                                window.location.href = '/imagine/' + uuid;
                            }
                        });
                }, 5000);
            })
            .catch(function (data) {
                document.getElementById('js-loading-generate').classList.add('d-none')
                document.getElementById('js-button-generate').classList.remove('d-none')

                if (data.response.status === 401) {
                    new bootstrap
                        .Modal(document.getElementById('modalLogin')).show();
                } else if (data.response.status === 500) {
                    document.getElementById('js-error-generate').innerHTML = 'Sorry, server error'
                    document.getElementById('js-error-generate').classList.remove('d-none')
                } else {
                    document.getElementById('js-error-generate').innerHTML = data.response.data.message
                    document.getElementById('js-error-generate').classList.remove('d-none')
                }
            });
    });

if (document.getElementById('form_auth'))
    document.getElementById('form_auth').addEventListener('submit', function (event) {
        event.preventDefault();
        document.getElementById('js-loading').classList.remove('d-none')
        document.getElementById('js-button').classList.add('d-none')
        document.getElementById('js-error').classList.add('d-none')

        axios.post('/login', {
            email: document.getElementById('email').value,
            password: document.getElementById('password').value,
        })
            .then(function (data) {
                location.reload()
            })
            .catch(function (data) {
                let error = 'Error auth!'
                console.log(data.response.data.error)
                if (data.response.data.error) {
                    error = data.response.data.error;
                }
                document.getElementById('js-loading').classList.add('d-none')
                document.getElementById('js-button').classList.remove('d-none')
                document.getElementById('js-error').innerHTML = error
                document.getElementById('js-error').classList.remove('d-none')
            });

    });

if (document.getElementById('js-gallery')) {
    let page = 1;

    function loadMoreData(page) {
        document.getElementById('js-loading-gallery').classList.remove('d-none')
        axios.get('?page=' + page)
            .then(function (data) {
                if (data.data.html === "") {
                    document.getElementById('js-loading-gallery').innerHTML = '';
                    page = 0
                    return;
                }
                document.getElementById('js-loading-gallery').classList.add('d-none')
                document.getElementById('js-gallery-items').innerHTML += data.data.html

                const likes = document.querySelectorAll('.like_block .like');

                for (let i = 0; i < likes.length; i++) {
                    likes[i].addEventListener('click', onClickLike, false);
                }

                const models = document.querySelectorAll('.model');

                for (let i = 0; i < likes.length; i++) {
                    models[i].addEventListener('click', onClickModel, false);
                }


            })
    }

    document.addEventListener("DOMContentLoaded", function () {
        document.addEventListener("scroll", function () {
            if (page === 0) {
                return;
            }
            const body = document.body,
                html = document.documentElement;

            const height = Math.max(body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight);

            if (window.pageYOffset + window.innerHeight >= height - 5) {
                page++;
                loadMoreData(page);
            }
        });
    });

    const likes = document.querySelectorAll('.like_block .like');

    for (let i = 0; i < likes.length; i++) {
        likes[i].addEventListener('click', onClickLike, false);
    }

    function onClickLike(event) {
        const hasLike = this.classList.contains("like_on");
        let uuid = this.parentElement.getAttribute('data-uuid')
        let count = this.parentElement.querySelector('.count').innerHTML
        if (hasLike) {
            this.classList.remove('like_on')
            this.parentElement.querySelector('.count').innerHTML = parseInt(count) - 1;
        } else {
            this.classList.add('like_on')
            this.parentElement.querySelector('.count').innerHTML = parseInt(count) + 1;
        }
        const el = this
        axios.post('/api/like/' + uuid)
            .catch(function (data) {
                let count = el.parentElement.querySelector('.count').innerHTML
                el.classList.remove('like_on')
                el.parentElement.querySelector('.count').innerHTML = parseInt(count) - 1;
                if (data.response.status === 401) {
                    new bootstrap
                        .Modal(document.getElementById('modalLogin')).show();
                }
            });

    }

    const models = document.querySelectorAll('.model');

    for (let i = 0; i < likes.length; i++) {
        models[i].addEventListener('click', onClickModel, false);
    }

    function onClickModel()
    {
        const uuid = this.parentElement.querySelector('.like_block').getAttribute('data-uuid')
        const src = this.src;
        const prompt = this.parentElement.querySelector('.prompt').innerHTML
        const user = this.parentElement.querySelector('.user').innerHTML
        const hasLike = this.parentElement.querySelector('.like_block .like').classList.contains("like_on");

        const modal = document.getElementById('modalGallery');
        modal.querySelector('.uuid').innerHTML = uuid
        modal.querySelector('.prompt').innerHTML = prompt
        modal.querySelector('.user').innerHTML = user
        modal.querySelector('.model').src = src
        modal.querySelector('.hasLike').innerHTML = hasLike

        const voteButton = document.getElementById('modalGallery-vote');
        if (modal.querySelector('.hasLike').innerHTML === 'true') {
            voteButton.classList.remove('btn-outline-success')
            voteButton.classList.add('btn-outline-danger')
            voteButton.innerText = 'Revoke the vote!';
        } else {
            voteButton.classList.add('btn-outline-success')
            voteButton.classList.remove('btn-outline-danger')
            voteButton.innerText = 'Vote for this!';
        }

        new bootstrap
            .Modal(document.getElementById('modalGallery')).show();
    }

    document.getElementById('modalGallery-vote').addEventListener('click', function () {
        const modal = document.getElementById('modalGallery');
        const uuid = modal.querySelector('.uuid').innerHTML

        const likes = document.querySelectorAll('.like_block');
        let likeBlock;
        for (let i = 0; i < likes.length; i++) {
            if (likes[i].getAttribute('data-uuid') === uuid) {
                likeBlock = likes[i];
                break;
            }
        }

        let count = likeBlock.querySelector('.count').innerHTML

        const hasLike = modal.querySelector('.hasLike').innerHTML
        const voteButton = document.getElementById('modalGallery-vote');
        if (hasLike === 'false') {
            voteButton.classList.remove('btn-outline-success')
            voteButton.classList.add('btn-outline-danger')
            voteButton.innerText = 'Revoke the vote!';
            modal.querySelector('.hasLike').innerHTML = 'true';
            likeBlock.querySelector('.like').classList.add('like_on')
            likeBlock.querySelector('.count').innerHTML = parseInt(count) + 1;
        } else {
            voteButton.classList.add('btn-outline-success')
            voteButton.classList.remove('btn-outline-danger')
            voteButton.innerText = 'Vote for this!';
            modal.querySelector('.hasLike').innerHTML = 'false';
            likeBlock.querySelector('.like').classList.remove('like_on')
            likeBlock.querySelector('.count').innerHTML = parseInt(count) - 1;
        }

        axios.post('/api/like/' + uuid)
            .catch(function (data) {
                voteButton.classList.add('btn-outline-success')
                voteButton.classList.remove('btn-outline-danger')
                voteButton.innerText = 'Vote for this!';
                modal.querySelector('.hasLike').innerHTML = 'false';
                let count = likeBlock.querySelector('.count').innerHTML
                likeBlock.querySelector('.like').classList.remove('like_on')
                likeBlock.querySelector('.count').innerHTML = parseInt(count) - 1;
                if (data.response.status === 401) {
                    new bootstrap
                        .Modal(document.getElementById('modalLogin')).show();
                }
            });

    });

}
